import { Box, Button, Paper, Stepper, Step, StepLabel, Snackbar, Alert, Divider, Grid2 as Grid } from '@mui/material';
import { createRef, useState, useRef, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ImageNotSupported } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';

import {
  states,
  setupIndexedDB,
  convertToBase64,
  formatPhoneNumber,
  getDynamicRequired,
  setResizedBusinessLogo,
  saveFormDataToIndexedDB,
  loadFormDataFromIndexedDB,
  deleteFormDataFromIndexedDB,
} from '../../helpers';
import { stepFields, getAllStepFields, getCommentFields, getDocFieldNames, calculateStepsStatus } from './steps';
import SelectTextField from '../../components/fields/select-text-field';
import UploadFileField from '../../components/fields/upload-file-field';
import TextInputField from '../../components/fields/text-input-field';
import SignatureField from '../../components/fields/signature-field';
import CheckboxField from '../../components/fields/checkbox-field';
import SelectField from '../../components/fields/select-field';
import RadioField from '../../components/fields/radio-field';
import CheckmarkAnimation from '../../components/checkmark';
import PageLoader from '../../components/page-loader';
import useDebounce from '../../hooks/debounce';

import styles from './index.module.css';

const Intake = () => {
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const otpToken = sessionStorage.getItem('otpToken');
  const notificationId = searchParams.get('notificationId');

  const allStepFields = getAllStepFields();
  const docFieldNames = getDocFieldNames();
  const initialFormData = allStepFields.reduce((acc, field) => {
    if (field.name) {
      acc[field.name] = '';
    }
    return acc;
  }, {});

  const refs = useRef([]);
  if (Object.keys(refs.current).length === 0) {
    allStepFields.forEach((field) => {
      if (field.name && !refs.current[field.name]) {
        refs.current[field.name] = createRef();
      }
    });
  }

  const steps = Array.from(new Set(stepFields.map((fields) => fields[0]?.step)));

  const getMainStepIndex = (step) => {
    const stepName = stepFields[step][0]?.step;
    return steps.indexOf(stepName);
  };

  const mainStepIndices = steps.map((stepName) => {
    return stepFields.map((fields, index) => (fields[0]?.step === stepName ? index : -1)).filter((index) => index !== -1);
  });

  const mainStepStartIndices = steps.map((stepName) => stepFields.findIndex((fields) => fields[0]?.step === stepName));

  const isStepCompleted = (stepIndex) => {
    const indices = mainStepIndices[stepIndex];
    return indices.every((index) => completedSteps.includes(index));
  };

  const [dynamicState, setDynamicState] = useState({ states });
  const [formData, setFormData] = useState(initialFormData);
  const [docsRequested, setDocsRequested] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [patientId, setPatientId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({});
  const [errors, setErrors] = useState({});

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});

  const validateCurrentStep = () => {
    const newErrors = {};
    const isLastStep = activeStep === stepFields.length - 1;
    const dataSetReference = isLastStep ? allStepFields : stepFields[activeStep];

    for (const field of dataSetReference) {
      const { name, required, allRequired, pattern } = field;
      const value = formData[name];

      const isFieldRequired = getDynamicRequired(formData, required);
      if (isFieldRequired) {
        if (Array.isArray(value) && value.length === 0) {
          newErrors[name] = true;
        } else if (!value || (value && value.trim && value.trim() === '') || value.length < 1) {
          newErrors[name] = true;
        }
      }
      if (allRequired && !field.options.every((o) => value.includes(o.value))) {
        newErrors[name] = true;
      }
      if (isFieldRequired && pattern && value && !new RegExp(pattern).test(value)) {
        newErrors[name] = true;
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleStepClick = (index) => {
    if (formComplete) {
      return;
    }

    const lastStepCompleted = completedSteps[completedSteps.length - 1];
    const targetActiveStep = mainStepStartIndices[index];

    if (completedSteps.includes(targetActiveStep) || targetActiveStep === lastStepCompleted + 1) {
      setActiveStep(targetActiveStep);
    }
  };

  const handleNext = () => {
    const fieldErrors = validateCurrentStep();
    if (Object.keys(fieldErrors).length > 0) {
      focusOnFirstError(fieldErrors);
      return;
    }

    if (!completedSteps.includes(activeStep)) {
      setCompletedSteps((prevState) => [...prevState, activeStep]);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));
  };

  const focusOnFirstError = (errors) => {
    for (const field in errors) {
      if (errors[field]) {
        const ref = refs.current[field];
        if (ref?.focus) {
          ref.focus();
          break;
        } else if (ref?.current?.focus) {
          ref.current.focus();
          break;
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (activeStep === stepFields.length - 1) {
      const fieldErrors = validateCurrentStep();
      if (Object.keys(fieldErrors).length === 0) {
        setIsRequesting(true);
        setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));

        const fieldsForComments = getCommentFields().map((field) => {
          return {
            key: field.name,
            isDoc: !!field.isDoc,
          };
        });

        let updatedFormData = { ...formData };
        if (formData['patientIsResponsibleParty'] === 'Yes') {
          updatedFormData = {
            ...formData,
            responsibleSalutation: formData.patientSalutation,
            responsibleFirstName: formData.patientFirstName,
            responsibleLastName: formData.patientLastName,
            responsibleDateOfBirth: formData.patientDateOfBirth,
            responsibleStreetAddress: formData.patientStreetAddress,
            responsibleAddressLine2: formData.patientAddressLine2,
            responsibleCity: formData.patientCity,
            responsibleState: formData.patientState,
            responsibleZip: formData.patientZip,
            responsiblePhone: formData.patientPhone,
            responsibleEmail: formData.patientEmail,
            responsibleRelationshipId: dynamicState['relationships'].filter((r) => r.label === 'Self')[0].value,
          };
        }

        const res = await fetch('/api/patients', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${otpToken}`,
            'Content-Type': 'application/json',
            'Notification-ID': notificationId,
            'Client-ID': clientId,
          },
          body: JSON.stringify({ ...updatedFormData, fieldsForComments }),
        });
        const resData = await res.json();

        if (res.ok) {
          setFormComplete(true);
          await deleteFormDataFromIndexedDB(notificationId);
          setCompletedSteps(Array.from({ length: stepFields.length }, (_, i) => i));
        } else {
          setToastOpen(true);
          setToastMessage({ label: `Failed to create or update patient!\n${resData.message}`, severity: 'error' });
        }

        setIsRequesting(false);
      } else {
        focusOnFirstError(fieldErrors);
      }
    } else {
      handleNext();
    }
  };

  const shouldShowField = (field) => {
    if (typeof field.hidden === 'object') {
      if (typeof field.hidden.value === 'boolean') {
        return field.hidden.value && formData[field.hidden.name];
      }

      const isHidden =
        field.hidden.value.includes(formData[field.hidden.name]) ||
        !formData[field.hidden.name] ||
        formData[field.hidden.name].trim() === '';
      return !isHidden;
    }

    return field.hidden ? false : true;
  };

  const renderStepContent = (fields) => {
    return (
      <Grid container spacing={2} marginBottom={4}>
        {fields.map((field, index) => {
          if (field.step) return null;
          return (
            shouldShowField(field) && (
              <Grid size={12} key={index}>
                {field.type === 'divider' ? (
                  <Divider sx={{ borderStyle: 'dotted', margin: '10px 0 15px 0' }} />
                ) : field.type === 'element' ? (
                  <div className={styles.contentContainer}>{field.content}</div>
                ) : field.type === 'file' ? (
                  <UploadFileField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                  />
                ) : field.type === 'select' ? (
                  <SelectField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                    dynamicState={dynamicState}
                  />
                ) : field.type === 'radio' ? (
                  <RadioField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                  />
                ) : field.type === 'checkbox' ? (
                  <CheckboxField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                  />
                ) : field.type === 'select-text' ? (
                  <SelectTextField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                    dynamicState={dynamicState}
                  />
                ) : field.type === 'signature' ? (
                  <SignatureField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                  />
                ) : (
                  <TextInputField
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    setErrors={setErrors}
                    refs={refs}
                    errors={errors}
                  />
                )}
              </Grid>
            )
          );
        })}
      </Grid>
    );
  };

  useEffect(() => {
    const initializeIndexedDB = async () => {
      try {
        await setupIndexedDB();
      } catch (error) {
        console.error('❌ Failed to setup IndexedDB!', error);
      }
    };

    initializeIndexedDB();
  }, []);

  useEffect(() => {
    const getClient = async () => {
      const res = await fetch(`/api/clients/${clientId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${otpToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data.client) {
          setClient(data.client);
          if (data.client.business_logo) {
            setResizedBusinessLogo(data.client.business_logo, 200, setClient);
          }
        } else {
          setNotFound(true);
        }
      } else {
        setNotFound(true);
      }
    };

    const getNotificationById = async () => {
      const res = await fetch(`/api/notifications/${notificationId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${otpToken}`,
          'Content-Type': 'application/json',
          'Client-ID': clientId,
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (!data.notification_id) {
          setNotFound(true);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            patientId: data.patient_id,
          }));
          setPatientId(data.patient_id);
          setPatientName(`${data.first_name}`);
        }
      } else {
        setNotFound(true);
      }
    };

    const getIntakeData = async () => {
      const res = await fetch('/api/intake-data', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${otpToken}`,
          'Content-Type': 'application/json',
          'Notification-ID': notificationId,
          'Client-ID': clientId,
        },
      });

      if (!res.ok) {
        setMaintenance(true);
        return;
      }

      const data = await res.json();
      if (Object.keys(data.intake).length) {
        let formData = null;
        try {
          const savedFormData = await loadFormDataFromIndexedDB(notificationId);
          if (savedFormData) {
            formData = savedFormData;
          }
        } catch (error) {
          console.error('❌ Failed to load form data from IndexedDB!', error);
          formData = null;
        }
        setFormData((prevState) => ({
          ...prevState,
          ...data.intake,
          ...(formData || {}),
        }));
      }
      setDynamicState((prevState) => ({
        ...prevState,
        schools: data.schools.map((s) => ({
          label: s.Name,
          value: s.Id,
        })),
        salutations: data.salutations.map((s) => ({
          label: s,
          value: s,
        })),
        relationships: data.relationships.map((r) => ({
          label: r.Description,
          value: r.Id,
        })),
        insurances: data.insurances
          .map((r) => ({
            label: r.Name === 'Default Insurance Carrier' ? 'Other' : r.Name,
            value: r.Id,
          }))
          .sort((a, b) => {
            if (a.label === 'Other') return -1;
            if (b.label === 'Other') return 1;
            return a.label.localeCompare(b.label);
          }),
        employers: data.employers
          .map((r) => ({
            label: r.Name === 'Default Employer' ? 'Other' : r.Name,
            value: r.Id,
          }))
          .sort((a, b) => {
            if (a.label === 'Other') return -1;
            if (b.label === 'Other') return 1;
            return a.label.localeCompare(b.label);
          }),
      }));
    };

    const init = async () => {
      await Promise.all([getClient(), getNotificationById()]);
      await getIntakeData();
      setLoading(false);
    };

    init();
  }, [otpToken, clientId, notificationId]);

  useEffect(() => {
    if (loading || docsRequested || !patientId) return;

    async function getPatientDocs() {
      const documents = docFieldNames
        .filter((name) => formData[name])
        .map((name) => ({
          field: name,
          value: formData[name],
        }));

      if (documents.length) {
        try {
          const fetchPromises = documents.map(async (document) => {
            const res = await fetch(`/api/patients/docs/${patientId}/${document.value}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${otpToken}`,
                'Content-Type': 'application/json',
                'Notification-ID': notificationId,
                'Client-ID': clientId,
              },
            });

            if (res.ok) {
              const data = await res.blob();
              const base64 = await convertToBase64(data);
              return { field: document.field, file: base64 };
            }
          });

          const results = await Promise.all(fetchPromises);
          results.forEach((result) => {
            if (result) {
              setFormData((prevState) => ({
                ...prevState,
                [result.field]: result.file,
              }));
            }
          });
        } catch (error) {
          console.error('❌ Failed to fetch patient documents!', error);
        }
      }
    }

    setDocsRequested(true);
    getPatientDocs();
  }, [otpToken, clientId, notificationId, loading, docsRequested, docFieldNames, patientId]);

  useEffect(() => {
    const status = calculateStepsStatus(completedSteps, formComplete, activeStep);
    const patchNotificationStatus = async () => {
      await fetch(`/api/notifications/${notificationId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${otpToken}`,
          'Content-Type': 'application/json',
          'Client-ID': clientId,
        },
        body: JSON.stringify({ status }),
      });
    };
    patchNotificationStatus();
  }, [otpToken, clientId, notificationId, completedSteps, formComplete, activeStep]);

  useDebounce(
    async () => {
      if (loading || isRequesting || formComplete || !Object.keys(formData).length) return;
      try {
        await saveFormDataToIndexedDB(formData, notificationId);
      } catch (error) {
        console.error('❌ Failed to save form data to IndexedDB!', error);
      }
    },
    500,
    [loading, isRequesting, formComplete, formData, notificationId],
  );

  if (loading) {
    return <PageLoader isRequesting={true} />;
  }

  return (
    <main className={styles.main}>
      <header className={styles.header} id="header">
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid size={6}>
            {client.businessLogo ? (
              <img
                alt={client.businessName}
                src={client.businessLogo}
                title={client.businessName}
                className={styles.businessLogo}
                style={{ maxWidth: client.businessLogoWidth }}
              />
            ) : (
              <ImageNotSupported fontSize="large" color="disabled" />
            )}
          </Grid>
          <Grid size={6}>
            {client.phone && (
              <h2>
                <a href={`tel:${client.phone}`}>
                  <PhoneIcon /> {formatPhoneNumber(client.phone)}
                </a>
              </h2>
            )}
          </Grid>
        </Grid>
      </header>

      {notFound ? (
        <Paper elevation={2} className={styles.paperNotFound}>
          <h1>
            <span>404</span>
            <br />
            Page Not Found
          </h1>
          <p>The page you are looking for does not exist.</p>
        </Paper>
      ) : maintenance ? (
        <Paper elevation={2} className={styles.maintenance}>
          <h1>
            <span>Please Check Back</span>
            <br />
            Dolphin Management System Unavailable
          </h1>
          <p>We apologize for the inconvenience and appreciate your patience.</p>
        </Paper>
      ) : (
        <Paper elevation={2} className={styles.paper}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={getMainStepIndex(activeStep)} alternativeLabel className={styles.stepper}>
              {steps.map((label, index) => (
                <Step key={label} completed={isStepCompleted(index)} sx={{ cursor: 'pointer' }}>
                  <StepLabel onClick={() => handleStepClick(index)}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={styles.formContainer}>
              {activeStep === 0 ? (
                <>
                  <h1 className={styles.patientName}>Hi {patientName},</h1>
                  <h3 className={styles.mainTitle}>Please enter your information below</h3>
                  <p className={styles.mainDescription}>
                    We value your privacy and security. All information you provide will be kept confidential and securely stored.
                    Your personal details, including sensitive data, are protected using the latest encryption technology to
                    ensure your privacy. Please take a moment to complete the form carefully. If you have any questions, feel free
                    to reach out!
                  </p>
                </>
              ) : formComplete ? (
                <>
                  <CheckmarkAnimation message="Registration Complete!" />
                  <p className={styles.mainDescription}>
                    We value your privacy and security. All information you provided will be kept confidential and securely
                    stored. Your personal details, including sensitive data, are protected using the latest encryption technology
                    to ensure your privacy.
                    <br />
                    <br />
                    <strong>Please see the front desk.</strong>
                  </p>
                </>
              ) : null}
              {!formComplete ? (
                <form onSubmit={handleSubmit} noValidate>
                  {renderStepContent(stepFields[activeStep])}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button variant="contained" type="submit">
                      {activeStep === stepFields.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </form>
              ) : null}
            </div>
          </Box>
        </Paper>
      )}
      <footer>
        <p>Copyright &copy; 2024 - Health of Your Space, LLC.</p>
      </footer>
      <PageLoader isRequesting={isRequesting} />
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastMessage.severity} sx={{ width: '100%' }}>
          {toastMessage.label}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Intake;
