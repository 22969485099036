import { Alert, Box, Button, MenuItem, Paper, Select, Snackbar, Tab, Tabs, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';

import PageLoader from '../../../../components/page-loader';
import Header from '../../../../components/header';

import styles from './index.module.css';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className={styles.panel}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    key: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const Api = () => {
  const [appLogs, setAppLogs] = useState([]);
  const [records, setRecords] = useState({});
  const [loading, setLoading] = useState(true);
  const [logLevel, setLogLevel] = useState('ALL');
  const [cachedKeys, setCachedKeys] = useState(0);
  const token = sessionStorage.getItem('authToken');

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [intakeDataId, setIntakeDataId] = useState('');
  const [notificationId, setNotificationId] = useState('');

  const filteredLogs = logLevel === 'ALL' ? appLogs : appLogs.filter((log) => log.level === logLevel);

  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleNotificationChange = (e) => {
    const sanitizedValue = e.target.value.replace(/['"`]/g, '');
    setNotificationId(sanitizedValue);
  };

  const handleIntakeDataChange = (e) => {
    const sanitizedValue = e.target.value.replace(/['"`]/g, '');
    setIntakeDataId(sanitizedValue);
  };

  const handleCacheFlush = async () => {
    const res = await fetch('/api/admin/cache/flush', {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();

    if (res.ok) {
      setToastMessage({ label: data.message, severity: 'success' });
    } else {
      setToastMessage({ label: data.error, severity: 'error' });
    }

    setToastOpen(true);
  };

  const handleDeleteNotification = async () => {
    const res = await fetch(`/api/admin/notifications/${notificationId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();

    if (res.ok) {
      setNotificationId('');
      setToastMessage({ label: data.message, severity: 'success' });
    } else {
      setToastMessage({ label: data.error, severity: 'error' });
    }

    setToastOpen(true);
  };

  const handleDeleteIntakeData = async () => {
    const res = await fetch(`/api/admin/intake-data/${intakeDataId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await res.json();

    if (res.ok) {
      setIntakeDataId('');
      setToastMessage({ label: data.message, severity: 'success' });
    } else {
      setToastMessage({ label: data.error, severity: 'error' });
    }

    setToastOpen(true);
  };

  useEffect(() => {
    const getRecords = async () => {
      const res = await fetch('/api/admin/records', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        setAppLogs(data.appLogs);
        setRecords(data.records);
        setCachedKeys(data.cachedKeys ?? 0);
      }

      setLoading(false);
    };

    getRecords();
    const intervalId = setInterval(getRecords, 5000);
    return () => clearInterval(intervalId);
  }, [token]);

  if (loading) {
    return <PageLoader isRequesting={true} />;
  }

  return (
    <main className={styles.main}>
      <Header title="API Manager" setToastOpen={setToastOpen} setToastMessage={setToastMessage} />
      <Paper elevation={2} className={styles.paper}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.tabsBg}>
          <Tabs value={value} onChange={handleChange} aria-label="Dashboard Navigation">
            <Tab label="All Records" {...a11yProps(0)} />
            <Tab label="Application Logs" {...a11yProps(1)} />
            <Tab label="Delete Notification" {...a11yProps(2)} />
            <Tab label="Delete Intake Data" {...a11yProps(3)} />
            <Tab label="Redis Management" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ReactJson src={records} collapsed={true} enableClipboard={true} name="All Records" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Paper elevation={0} className={styles.filterContainer}>
            <Select value={logLevel} onChange={(e) => setLogLevel(e.target.value)} displayEmpty fullWidth>
              <MenuItem value="ALL">
                Application Logs -&nbsp;<strong>All ⚫️</strong>
              </MenuItem>
              <MenuItem value="INFO">
                Application Logs -&nbsp;<strong>Info 🔵</strong>
              </MenuItem>
              <MenuItem value="WARN">
                Application Logs -&nbsp;<strong>Warn 🟠</strong>
              </MenuItem>
              <MenuItem value="ERROR">
                Application Logs -&nbsp;<strong>Error 🔴</strong>
              </MenuItem>
            </Select>
          </Paper>
          <ReactJson src={filteredLogs} collapsed={false} enableClipboard={true} name="Application Logs" />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
            <TextField
              label="Notification ID:"
              name="notificationId"
              fullWidth
              value={notificationId}
              onChange={handleNotificationChange}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDeleteNotification}
              sx={{ minHeight: '56px' }}
            >
              Delete Notification
            </Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
            <TextField
              label="Intake Data ID:"
              name="intakeDataId"
              fullWidth
              value={intakeDataId}
              onChange={handleIntakeDataChange}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDeleteIntakeData}
              sx={{ minHeight: '56px' }}
            >
              Delete Intake Data
            </Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
            <p>Current Cached Keys: {cachedKeys}</p>
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCacheFlush}
              sx={{ minHeight: '56px' }}
            >
              Flush Cache
            </Button>
          </Box>
        </CustomTabPanel>
      </Paper>
      <footer>
        <p>Copyright &copy; 2024 - Health of Your Space, LLC.</p>
      </footer>
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastMessage.severity} sx={{ width: '100%' }}>
          {toastMessage.label}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Api;
