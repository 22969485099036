import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  Pagination,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  TableSortLabel,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';

import { formatPhoneNumber, getDateTimestamp } from '../../../../helpers';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Loader from '../../../../components/loader';

import styles from '../index.module.css';

const NotificationsTab = () => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [notifications, setNotifications] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [description, setDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const itemsPerPage = 10;

  const token = sessionStorage.getItem('authToken');
  const clientId = sessionStorage.getItem('clientId');

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (sortConfig.key) {
      const valueA = a[sortConfig.key].toString();
      const valueB = b[sortConfig.key].toString();
      if (valueA < valueB) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredNotifications = sortedNotifications.filter((notification) =>
    [`${notification.first_name} ${notification.last_name}`, notification.phone_number, notification.status].some((field) =>
      field.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  const paginatedNotifications = filteredNotifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    const getNotifications = async () => {
      const res = await fetch(`/api/notifications`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Client-ID': clientId,
        },
      });

      if (res.ok) {
        const data = await res.json();
        setNotifications(data.map((r) => ({ ...r, phone_number: formatPhoneNumber(r.phone_number) })));
      }

      setLoading(false);
    };

    getNotifications();
    const intervalId = setInterval(getNotifications, 5000);
    return () => clearInterval(intervalId);
  }, [clientId, token]);

  const handleViewMessage = (notification) => {
    setDescription(
      `To: ${notification.first_name} ${notification.last_name}\nPhone: ${notification.phone_number}\n\n${notification.message}`,
    );
    setMessage(`Successfully sent message on: ${getDateTimestamp(notification.created_at)}`);
    setDialogOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <section className={styles.sectionColumn}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <FormControl>
            <InputLabel htmlFor="search">Search</InputLabel>
            <OutlinedInput
              id="search"
              label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ marginBottom: '20px' }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  {searchQuery ? (
                    <IconButton onClick={() => setSearchQuery('')}>
                      <Close />
                    </IconButton>
                  ) : (
                    <Search />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'first_name'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('first_name')}
                    >
                      Full Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'phone_number'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('phone_number')}
                    >
                      Phone Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>Message</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'status'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'updated_at'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('updated_at')}
                    >
                      Last Updated
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedNotifications.map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell
                      title={notification.notification_id}
                    >{`${notification.first_name} ${notification.last_name}`}</TableCell>
                    <TableCell>{notification.phone_number}</TableCell>
                    <TableCell>
                      <Button variant="outlined" size="small" onClick={() => handleViewMessage(notification)}>
                        View
                      </Button>
                    </TableCell>
                    <TableCell>{notification.status}</TableCell>
                    <TableCell>{getDateTimestamp(notification.updated_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {filteredNotifications.length === 0 && (
              <Box display="flex" flexDirection="column" alignItems="center" m={4}>
                <Typography variant="h6" color="textSecondary" sx={{ fontWeight: '300', fontSize: '18px' }}>
                  No Patient Notifications Available
                </Typography>
              </Box>
            )}
          </TableContainer>
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            className={styles.pagination}
            count={Math.ceil(filteredNotifications.length / itemsPerPage)}
          />
          <ConfirmDialog
            onConfirm={() => setDialogOpen(false)}
            onClose={() => setDialogOpen(false)}
            title="Message Sent to Patient"
            description={description}
            confirmLabel="Close"
            hideCloseBtn={true}
            message={message}
            open={dialogOpen}
          />
        </>
      )}
    </section>
  );
};

export default NotificationsTab;
