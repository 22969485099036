import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import NotFound from '../pages/not-found';

import OtpModal from './otp-modal';

const ProtectedRoute = ({ children, roles, otp }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [otpMessage, setOtpMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [otpError, setOtpError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [otpId, setOtpId] = useState(null);
  const navigate = useNavigate();

  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const notificationId = searchParams.get('notificationId');

  const handleOtpSubmit = async (code) => {
    const res = await fetch('/api/otp/validate', {
      method: 'POST',
      headers: {
        'Client-ID': clientId,
        'Notification-ID': notificationId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ otpId, code }),
    });

    if (res.ok) {
      const data = await res.json();
      sessionStorage.setItem('otpToken', data.token);
      setIsAuthenticated(true);
      setIsLoading(false);
      setOtpOpen(false);
    } else {
      setOtpError(true);
    }
  };

  const createOtp = useCallback(async () => {
    const res = await fetch('/api/otp', {
      method: 'POST',
      headers: {
        'Client-ID': clientId,
        'Notification-ID': notificationId,
        'Content-Type': 'application/json',
      },
    });

    if (res.ok) {
      const data = await res.json();
      setOtpMessage(
        `<strong>Hi ${data?.firstName}</strong>,<br />A one-time passcode has been sent to your phone number (***${data?.phoneNumber}).`,
      );
      setOtpId(data?.otpId);
      setOtpOpen(true);
    } else {
      setNotFound(true);
    }
  }, [clientId, notificationId, setOtpMessage, setOtpId, setOtpOpen, setNotFound]);

  useEffect(() => {
    const otpToken = sessionStorage.getItem('otpToken');
    const token = sessionStorage.getItem('authToken');

    if (otp && !otpToken) {
      createOtp();
      return;
    }

    if (!otp && (!token || !roles)) {
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    const validateTokens = async () => {
      try {
        const res = await fetch('/api/token/validate', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${otp ? otpToken : token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ roles, notificationId }),
        });

        if (res.ok) {
          setIsAuthenticated(true);
          setIsLoading(false);
        } else {
          if (otp) {
            sessionStorage.removeItem('otpToken');
            createOtp();
          } else {
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('clientId');
            setIsAuthenticated(false);
            setIsLoading(false);
          }
        }
      } catch (error) {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('otpToken');
        sessionStorage.removeItem('clientId');
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    validateTokens();
  }, [otp, roles, notificationId, navigate, createOtp]);

  if (notFound) {
    return <NotFound />;
  }

  if (otpOpen) {
    return (
      <OtpModal
        open={otpOpen}
        otpError={otpError}
        createOtp={createOtp}
        otpMessage={otpMessage}
        setOtpError={setOtpError}
        onModalSubmit={handleOtpSubmit}
      />
    );
  }

  if (isLoading) {
    return null;
  }

  return isAuthenticated ? children : <Navigate to="/admin/login" />;
};

export default ProtectedRoute;
