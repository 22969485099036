import {
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

import { autoFormatPhoneNumber, formatPhoneNumber, newPatientOption } from '../../helpers';
import PatientSearch from '../patient-search';
import ConfirmDialog from '../confirm-dialog';
import PageLoader from '../page-loader';

import styles from './index.module.css';

const IntakeModal = ({ open, onClose, setToastOpen, setToastMessage }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [notificationId, setNotificationId] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const firstNameRef = useRef(null);

  const token = sessionStorage.getItem('authToken');
  const clientId = sessionStorage.getItem('clientId');

  const handleChange = (e) => {
    setSelectedPatient(e);
    if (e?.Id === 'NEW') {
      setMessage('');
      setTimeout(() => {
        firstNameRef.current?.focus();
      }, 0);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const changedValue = [
      {
        Number: autoFormatPhoneNumber(value),
      },
    ];

    setSelectedPatient((prevState) => ({
      ...prevState,
      [name]: name === 'PhoneNumbers' ? changedValue : value,
    }));
  };

  const handleSubmit = async () => {
    setIsRequesting(true);
    setDialogOpen(false);

    const firstName = selectedPatient.FirstName.trim();
    const lastName = selectedPatient.LastName.trim();
    const phoneNumber =
      selectedPatient.PhoneNumbers && selectedPatient.PhoneNumbers[0] ? selectedPatient.PhoneNumbers[0].Number : 'N/A';
    const metaData = { firstName, lastName, phoneNumber };

    try {
      const smsResponse = await fetch('/api/sms/send', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, smsMessage: finalMessage }),
      });
      if (!smsResponse.ok) {
        throw new Error('Failed to send patient sms');
      }

      const messageResponse = await fetch(`/api/messages`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId,
          metaData,
          message: finalMessage,
          patientId: selectedPatient.Id,
        }),
      });
      if (!messageResponse.ok) {
        throw new Error('Failed to save patient message');
      }

      const messageData = await messageResponse.json();
      const notificationResponse = await fetch(`/api/notifications`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId,
          notificationId,
          patientId: selectedPatient.Id,
          messageId: messageData.messageId,
        }),
      });
      if (!notificationResponse.ok) {
        throw new Error('Failed to send notification');
      }

      await fetch(`/api/intake-data`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            patientFirstName: metaData.firstName,
            patientLastName: metaData.lastName,
            patientPhone: metaData.phoneNumber,
          },
          notificationId,
          clientId,
        }),
      });

      setToastMessage({ label: 'Successfully sent message to patient!', severity: 'success' });
    } catch (error) {
      setToastMessage({ label: error.message || 'Failed to send message to patient!', severity: 'error' });
    } finally {
      setMessage('');
      setToastOpen(true);
      setIsRequesting(false);
      setSelectedPatient(null);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const uuid = uuidv4();
    setNotificationId(uuid);

    const res = await fetch('/api/notifications/prepare', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientId, notificationId: uuid }),
    });
    const data = await res.json();
    setFinalMessage(`${message}\n\n${data.url}`);
    setDialogOpen(true);
  };

  useEffect(() => {
    if (selectedPatient?.FirstName || selectedPatient?.LastName) {
      setMessage(
        `Hi ${selectedPatient?.FirstName},\n\nPlease head to the patient portal by clicking the link below to complete your intake forms before your appointment. We look forward to seeing your smile!`,
      );
    } else if (!selectedPatient) {
      setMessage('');
    }
  }, [selectedPatient]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <form onSubmit={handleFormSubmit}>
        <DialogTitle id="modal-title" className={styles.modalTitle}>
          Send Intake Form to Patient <Close onClick={onClose} sx={{ cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="modal-description" sx={{ marginBottom: '20px' }}>
            Search for an existing patient or send to a new patient to create a message.
          </DialogContentText>
          <PatientSearch preSelectedPatient={selectedPatient} onChange={handleChange} />

          {selectedPatient && (
            <>
              <h3 className={styles.messageDetails}>Patient Message Details</h3>
              <TextField
                fullWidth
                margin="normal"
                label="First Name"
                name="FirstName"
                required={true}
                inputRef={firstNameRef}
                onChange={handleInputChange}
                slotProps={{
                  htmlInput: {
                    readOnly: selectedPatient.Id !== 'NEW',
                    minLength: 2,
                  },
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={selectedPatient.FirstName}
                className={selectedPatient.Id !== 'NEW' && styles.readOnly}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Last Name"
                name="LastName"
                required={true}
                onChange={handleInputChange}
                slotProps={{
                  htmlInput: {
                    readOnly: selectedPatient.Id !== 'NEW',
                    minLength: 2,
                  },
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={selectedPatient.LastName}
                className={selectedPatient.Id !== 'NEW' && styles.readOnly}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Phone Number"
                name="PhoneNumbers"
                required={true}
                onChange={handleInputChange}
                slotProps={{
                  htmlInput: {
                    readOnly: selectedPatient.Id !== 'NEW',
                    pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                    maxLength: 12,
                  },
                  inputLabel: {
                    shrink: true,
                  },
                }}
                value={
                  selectedPatient.PhoneNumbers && selectedPatient.PhoneNumbers[0] ? selectedPatient.PhoneNumbers[0].Number : 'N/A'
                }
                className={selectedPatient.Id !== 'NEW' && styles.readOnly}
              />
              <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                placeholder="Enter a message to send to the patient..."
                margin="normal"
                value={message}
                required={true}
                onChange={handleMessageChange}
                slotProps={{
                  htmlInput: {
                    minLength: 10,
                  },
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
              <p className={styles.note}>
                <strong>Note:</strong> Patient intake form link will automatically be applied to the end of the message.
              </p>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: '20px' }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button onClick={() => handleChange(newPatientOption)} color="primary" variant="outlined">
              New Patient
            </Button>
            <Box>
              <Button onClick={onClose} color="primary" className={styles.cancelButton}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={!selectedPatient} sx={{ marginLeft: '8px' }}>
                Send Message
              </Button>
            </Box>
          </Box>
        </DialogActions>
        <ConfirmDialog
          open={dialogOpen}
          onConfirm={handleSubmit}
          onClose={() => setDialogOpen(false)}
          title="Confirm Message to Patient"
          message="Message will be sent immediately upon confirming."
          description={
            selectedPatient &&
            `To: ${selectedPatient.FirstName} ${selectedPatient.LastName}\nPhone: ${formatPhoneNumber(selectedPatient.PhoneNumbers[0].Number)}\n\n${finalMessage}`
          }
        />
      </form>
      <PageLoader isRequesting={isRequesting} />
    </Dialog>
  );
};

export default IntakeModal;
